import * as React from "react";
import { useEffect } from "react";
import { NumericInput } from "./numeric-input";
import { AddToCart } from "./add-to-cart";

export default function Calculator({ variantId }) {
  const [couples, setCouples] = React.useState(0);
  const [TShirtsRequired, setTShirtsRequired] = React.useState(0);

  useEffect(() => {
    setTShirtsRequired(couples * 2);
  }, [couples]);

  return (
    <div
      style={{ margin: "5rem 0", padding: "0.5rem", border: "1px solid black" }}
    >
      <h2 style={{ fontSize: "1.5rem", fontWeight: "bold", marginBottom: "1.5rem"}}>
        Calculator example
      </h2>
      <p>How many couples are coming to your event?</p>
      <NumericInput
        onChange={(e) => setCouples(e.target.value)}
        onIncrement={() => setCouples((q) => q + 1)}
        onDecrement={() => setCouples((q) => Math.max(1, q - 1))}
        value={couples}
        min="1"
      />
      <p>
        Because there are <strong>{couples}</strong> coming you  
        require <strong>{TShirtsRequired}</strong> t-shirts
      </p>
      <AddToCart quantity={TShirtsRequired} variantId={variantId} available />
    </div>
  );
}
